'use client';
import { init, push } from '@socialgouv/matomo-next';
import { GoogleTagManager } from '@next/third-parties/google'
import { ProviderRedux } from '../GlobalRedux/provider';
import '../../styles/globals.css';
import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';
import MainLayout from '../components/Organisms/MainLayout/MainLayout';
import AuthLayout from '@/components/Organisms/AuthLayout/AuthLayout';

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL || '';
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID || '';
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const pathName = usePathname();
  const authPages = ['/', '/login', '/auth/login', '/auth/forgot-password', '/auth/registration', '/auth/verify-email', '/auth/reset-password'];
  const isAuthPage = authPages.includes(pathName);

  useEffect(() => {
    init({
      url: MATOMO_URL,
      siteId: MATOMO_SITE_ID,
    });
    push(['trackPageView']);
  }, [pathName]);

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap" />
        <GoogleTagManager gtmId={GTM_ID} />
      </head>
      <body className="scroll-container">
        <ProviderRedux>{!isAuthPage ? <MainLayout>{children}</MainLayout> : pathName === '/auth/verify-email' ? <div>{children}</div> : <AuthLayout>{children}</AuthLayout>}</ProviderRedux>
      </body>
    </html>
  );
}
