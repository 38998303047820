import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import formConfigJson from '../../app/company/add/formConfig.json';

// Define the types for form configuration, field state, and field errors
interface FieldConfig {
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  tooltipDescription?: string;
  step?: number;
  buttonType?: string;
}

interface FieldState {
  [key: string]: any;
}

interface FieldErrors {
  [key: string]: string | undefined;
}

interface FormState {
  formConfig: FieldConfig[];
  fieldState: FieldState;
  fieldErrors: FieldErrors;
  currentStep: number;
}

// Initial state with type
const initialState: FormState = {
  formConfig: [],
  fieldState: {},
  fieldErrors: {},
  currentStep: 0,
};

// Thunk to clear current and subsequent step data
export const clearCurrentAndSubsequentStepDataThunk = createAsyncThunk(
  'form/clearCurrentAndSubsequentStepData',
  async (_, { dispatch, getState }) => {
    const state: FormState = (getState() as any).form;
    const currentStep = state.currentStep;

    const currentStepTitle = state.formConfig[currentStep]?.title;

    if (!currentStepTitle) return;

    const currentStepIndex = formConfigJson.findIndex(
      (step) => step.title === currentStepTitle
    );
    if (currentStepIndex === -1) return;

    if (currentStepIndex === 0) {
      localStorage.removeItem('company_id');
      localStorage.removeItem('vat');
      localStorage.removeItem('company_name');
    }

    const fieldsToClear = formConfigJson
      .slice(currentStepIndex)
      .flatMap((step) => step.fields.map((field) => field.name));

    dispatch(clearFieldState(fieldsToClear));
    dispatch(clearFieldErrors(fieldsToClear));
  }
);

// Helper reducers for clearing field state and errors
const clearFieldState = (fieldsToClear: string[]) => ({
  type: 'form/clearFieldState',
  payload: fieldsToClear,
});

const clearFieldErrors = (fieldsToClear: string[]) => ({
  type: 'form/clearFieldErrors',
  payload: fieldsToClear,
});

// Create the slice
const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormConfig: (state, action: PayloadAction<FieldConfig[]>) => {
      state.formConfig = action.payload;
    },
    updateFieldState: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;
      state.fieldState[name] = value;
    },
    setFieldError: (
      state,
      action: PayloadAction<{ name: string; error: string }>
    ) => {
      const { name, error } = action.payload;
      state.fieldErrors[name] = error;
    },
    clearFieldError: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      delete state.fieldErrors[name];
    },
    resetFormState: (state) => {
      state.fieldState = {};
      state.fieldErrors = {};
      state.currentStep = 0;
      state.formConfig = [];
    },
    setFieldState: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;
      state.fieldState[name] = { value, error: state.fieldState[name]?.error };
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    clearCurrentStepErrors: (state) => {
      const currentStep = state.currentStep;
      Object.keys(state.fieldErrors).forEach((fieldName) => {
        const fieldConfig = state.formConfig.find(
          (step) => step.name === fieldName
        );
        if (fieldConfig && fieldConfig.step === currentStep) {
          delete state.fieldErrors[fieldName];
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearCurrentAndSubsequentStepDataThunk.fulfilled, (state, action) => {
        const currentStep = state.currentStep;
      
        const currentStepTitle = state.formConfig[currentStep]?.title;
      
        if (!currentStepTitle) return;
      
        const currentStepIndex = formConfigJson.findIndex((step) => step.title === currentStepTitle);
        const totalSteps = formConfigJson.length;
        if (currentStepIndex === -1) return;
      
        if (currentStepIndex === 0) {
            localStorage.removeItem("company_id");
            localStorage.removeItem("vat");
            localStorage.removeItem("company_name");
        }

        const fieldsToClear = formConfigJson
        .slice(currentStepIndex)
        .flatMap((step) => step.fields.map((field) => field.name));

        Object.keys(state.fieldState).forEach((fieldName) => {
        if (fieldsToClear.includes(fieldName)) {
            state.fieldState[fieldName] = '';
        }
        });

        // Update the fieldErrors by assigning undefined to the fields to be cleared
        Object.keys(state.fieldErrors).forEach((fieldName) => {
        if (fieldsToClear.includes(fieldName)) {
            state.fieldErrors[fieldName] = undefined;
        }
        });
      });
  },
});

export const {
  setFormConfig,
  updateFieldState,
  setFieldError,
  clearFieldError,
  resetFormState,
  setFieldState,
  setCurrentStep,
  clearCurrentStepErrors,
} = formSlice.actions;

export default formSlice.reducer;
