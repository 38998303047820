import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, MenuProps, Tooltip } from 'antd';
import { usePathname, useRouter } from 'next/navigation';
import AppHeader from '../AppHeader/AppHeader';
import { Footer } from 'antd/es/layout/layout';
import serviceUtil from 'src/Utils/serviceUtil/serviceUtil';
import CustomModal from "@/components/Atoms/CustomModal";
import { useTranslation } from 'react-i18next';

const { Header, Content, Sider } = Layout;

interface SubMenu {
  Menu_Label: string;
  isActive: boolean;
  Menu_URL: string;
  Menu_Icon?: string;
}

interface MenuItem {
  Menu_Label: string;
  isActive: boolean;
  Menu_URL: string;
  Menu_Icon: string;
  subMenu?: SubMenu[] | undefined;
}

type MenuItemType = Required<MenuProps>["items"][number];

export const getIconByName = (iconName: string, isSelected: boolean, isActive: boolean, isHovered: boolean): React.ReactNode => {
  const disabledIconStyle = { style: { color: '#737373' } };

  const getIconPath = (iconName: string, state: string) =>
    state ? `/${iconName}-${state}.svg` : `/${iconName}.svg`;

  const iconPath = !isActive
    ? getIconPath(iconName, "disabled")
    : isHovered && !isSelected && isActive
      ? getIconPath(iconName, "hovered")
      : isSelected && isActive
        ? getIconPath(iconName, "selected")
        : getIconPath(iconName, "");

  return iconName ? (
    <img
      src={iconPath}
      alt={iconName}
      {...(isActive ? disabledIconStyle : {})}
    />
  ) : (
    <></>
  );
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon: React.ReactNode,
  children?: MenuItemType[],
  isActive?: boolean
): MenuItemType {
  return {
    key,
    icon,
    children,
    label,
    className: isActive ? "" : "disabled-item",
  } as MenuItemType;
}

// Function to create Tooltip-wrapped Menu label
const createTooltipLabel = (label: string) => (
  <Tooltip title= { label } >
    <span>{ label } </span>
  </Tooltip>
)

const mapMenuItems = (
  menu: MenuItem[],
  openKeys: string[],
  setOpenKeys: (keys: string[]) => void,
  hoveredItem: string,
  setHoveredItem: (key: string) => void,
  pathName: string,
  setSelectedSubMenu: (url: string) => void
): MenuItemType[] => {
  const isAnySubmenuSelected = (subMenu?: SubMenu[]) => {
    return subMenu?.some((subItem) => pathName === subItem.Menu_URL) || false;
  };

  return menu?.map((item) => {
    const isSelected =
      pathName === item.Menu_URL || isAnySubmenuSelected(item.subMenu);
    const hasSubMenu = item.subMenu && item.subMenu.length > 0;

    return getItem(
      createTooltipLabel(item.Menu_Label),
      item.Menu_URL,
      getIconByName(
        item.Menu_Icon,
        isSelected,
        item.isActive,
        hoveredItem === item.Menu_URL
      ),
      hasSubMenu
        ? mapMenuItems(
          item?.subMenu,
          openKeys,
          setOpenKeys,
          hoveredItem,
          setHoveredItem,
          pathName,
          setSelectedSubMenu
        )
        : undefined,
      item.isActive
    );
  });
};

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathName = usePathname();
  const router = useRouter();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [hoveredItem, setHoveredItem] = useState<string>("");
  const [menu, setMenu] = useState<MenuItem[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string>(pathName);
  const [newUrl, setNewUrl] = useState<string>("");
  const { t, i18n } = useTranslation();

  const updateTranslatedMenu = async (menu:MenuItem[])=>{
    setMenu(menu)
  }
  useEffect(() => {
      const menu = localStorage.getItem("userMenu");
      const parsedMenu = JSON.parse(menu || "[]");
      setMenu(parsedMenu);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= 1024;
      setCollapsed(!isDesktop);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!menu || menu.length === 0) {
      return;
    }

    const findOpenKey = (menuItems: MenuItem[], currentPath: string): string | null => {
      for (const item of menuItems) {
        if (item.subMenu) {
          for (const subItem of item.subMenu) {
            if (subItem.Menu_URL === currentPath) {
              return item.Menu_URL;
            }
          }
        }
      }
      return null;
    };

    const openKey = findOpenKey(menu, pathName);
    if (openKey) {
      setOpenKeys([openKey]);
    }
  }, [menu, pathName]);



  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (!latestOpenKey) {
      setTimeout(() => {
        if (
          document.querySelector('.ant-menu-submenu-selected')?.classList &&
          !document.querySelector('.ant-menu-submenu-open')?.classList
        ) {
          document.querySelector('.ant-menu-submenu-selected')?.classList.remove("ant-menu-submenu-selected", "ant-menu-submenu-vertical")
          setSelectedSubMenu("")
        }
      }, 500)
    }
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
      setSelectedSubMenu(latestOpenKey);
    } else {
      setOpenKeys([]);
    }
  };

  const handleMenuClick = (itemKey: string, hasSubMenu: boolean) => {
    if (collapsed && hasSubMenu) {
      const isCurrentlyOpen = openKeys.includes(itemKey);
      setOpenKeys(isCurrentlyOpen ? [] : [itemKey]);
    } else if (!hasSubMenu) {
      const blockNavigation = localStorage.getItem('blockNavigation');
      if(blockNavigation && pathName !== itemKey){
        setNewUrl(itemKey)
        setIsModalVisible(true)
      }
      else {
        setSelectedSubMenu(itemKey);
        router.push(itemKey);
      }
    }
  };

  const getIcon = (
    icon: React.ReactNode,
    isActive: boolean,
    isSelected: boolean,
    isHovered: boolean
  ) => {
    const IconComponent =
      isSelected || isHovered
        ? React.cloneElement(icon as React.ReactElement)
        : icon;

    return isActive
      ? IconComponent
      : React.cloneElement(icon as React.ReactElement, {
        style: { color: "#737373" },
      });
  };

  const generateMenuItems = (items: MenuItemType[]): MenuProps['items'] => {
    return items?.map((item) => {
      const isSelected = pathName === item?.key;
      const hasSubMenu = item?.children && item?.children?.length > 0;

      return {
        key: item?.key,
        icon: getIcon(
          item?.icon,
          !item?.className?.includes("disabled-item"),
          isSelected,
          hoveredItem === item?.key
        ),
        label: item?.label,
        disabled: item?.className?.includes("disabled-item"),
        children: item?.children ? generateMenuItems(item?.children) : undefined,
        onClick: () => handleMenuClick(item?.key as string, hasSubMenu,),
        onMouseEnter: () => setHoveredItem(item?.key as string),
        onMouseLeave: () => setHoveredItem(""),
      } as MenuItemType;
    });
  };

  useEffect(() => {
    setSelectedSubMenu(pathName);
  }, [pathName]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    router.push(newUrl);
    setNewUrl("");
    setIsModalVisible(false);
    localStorage.removeItem('blockNavigation');
  }
  return (
    <Layout style={{ fontSize: '16px', minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} trigger={null} style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }} data-testid="antd-sider">
        <div className="flex justify-center items-center">
          {collapsed ? (
            <img src="/de-karb-logo-half.svg" alt="logo-icon" className="m-4" />
          ) : (
            <img src="/de-karb-logo-full.svg" alt="logo-icon" className="m-4" />
          )}
        </div>
        <Menu
          selectedKeys={[selectedSubMenu]}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={generateMenuItems(
            mapMenuItems(
              menu,
              openKeys,
              setOpenKeys,
              hoveredItem,
              setHoveredItem,
              selectedSubMenu,
              setSelectedSubMenu
            )
          )}
          className="mt-4"
          style={{ fontSize: "16px" }}
          triggerSubMenuAction="click"
        />
      </Sider>
      <Layout
        className={`${collapsed
          ? "md:ml-[50px] lg:ml-[80px]"
          : "md:ml-[200px] lg:ml-[260px] h-auto min-h-full"
          }`}
      >
        <div
          className={`${collapsed
            ? "fixed top-4 bg-transparent z-[101] md:left-[36px] lg:left-[62px]"
            : "fixed top-4 bg-transparent z-[101] md:left-[182px] lg:left-[242px]"
            }`}
        >
          <Button
            type="text"
            icon={
              collapsed ? (
                <img src="/circle_open.svg" alt="sider-open" />
              ) : (
                <img src="/circle_close.svg" alt="sider-close" />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{ fontSize: "16px", border: "none" }}
          />
        </div>
        <AppHeader selectedSubMenu={selectedSubMenu} setSelectedSubMenu={setSelectedSubMenu} updateTranslatedMenu={updateTranslatedMenu} />
        <Content
          style={{ overflow: 'initial', minHeight: '89%' }}
        >
          {children}
        </Content>
        {/* <br />
        <Footer
          style={{
            textAlign: 'center',
            background: '#f5f5f5',
            padding: '0px'
          }}
        >
        </Footer> */}
        <CustomModal
          isVisible={isModalVisible}
          onCancel={closeModal}
          onOk={handleOk}
          title={""}
          description={t("Are you sure you want to navigate to other page without saving your changes?")}
          iconType={"Warning"}
          primaryButtonText={"Yes"}
          secondaryButtonText="No"
      />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
