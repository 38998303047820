interface ErrorMessage {
  message: string;
  type: string;
  heading: string;
}

interface ErrorMessages {
  [key: string]: ErrorMessage;
}

const errorMessages: ErrorMessages = {
  // Register Messages
  REGISTER_EMAIL_REQUIRED: {
    message: "Email is required.",
    type: "Error",
    heading: "Input Required",
  },
  REGISTER_EMAIL_FORMAT_NOT_VALID: {
    message: "Email format is invalid.",
    type: "Error",
    heading: "Validation",
  },
  REGISTER_FIRSTNAME_REQUIRED: {
    message: "First name is required.",
    type: "Error",
    heading: "Input Required",
  },
  REGISTER_LASTNAME_REQUIRED: {
    message: "Last name is required.",
    type: "Error",
    heading: "Input Required",
  },
  REGISTER_PASSWORD_REQUIRED: {
    message: "Password is required.",
    type: "Error",
    heading: "Input Required",
  },
  REGISTER_PASSWORD_LENGTH: {
    message: "Password must be at least 8 characters long.",
    type: "Error",
    heading: "Password Requirement",
  },
  REGISTER_PASSWORD_CRITERIA_NOT_MATCH: {
    message:
      "Password must contain an uppercase, a lowercase, a number and a special character.",
    type: "Error",
    heading: "Password Requirement",
  },
  REGISTER_VERIFICATION_EMAIL_SENT: {
    message: "A verification link has been sent on your registered email ID.",
    type: "Info",
    heading: "Verification Email Sent",
  },
  REGISTER_EMAIL_ALREADY_EXISTS: {
    message: "The username or email already exists.",
    type: "Error",
    heading: "Email Exists",
  },
  REGISTER_COMPANY_NOT_REGISTERED: {
    message:
      "An error has occurred. Please contact your administrator for assistance.",
    type: "Error",
    heading: "Error",
  },
  REGISTER_EMAIL_TOKEN_NOT_VALIDATE: {
    message:
      "The verification link is not valid. Please ensure you've clicked on the correct link.",
    type: "Error",
    heading: "Invalid Email Verification Link",
  },
  REGISTER_EMAIL_TOKEN_IS_EXPIRED: {
    message:
      "Your email ID has not been verified because the link has expired. Please sign up again",
    type: "Error",
    heading: "Email Verification Link Expired",
  },
  REGISTER_EMAIL_TOKEN_IS_VALIDATE_SUCCESSFULLY: {
    message:
      "Your email ID has been verified successfully. Account Verification is now pending with your administrator.",
    type: "Success",
    heading: "Email Verification Successful",
  },
  REGISTER_VERIFICATION_PENDING: {
    message:
      "Your email ID was verified. Account verification is pending with your administrator.",
    type: "Info",
    heading: "Account Verification Pending",
  },
  REGISTER_EMAIL_VERIFICATION_PENDING: {
    message:
      "Your email ID has not been verified. Please check your inbox to verify.",
    type: "Info",
    heading: "Email Verification Pending",
  },

  // Login Messages
  LOGIN_EMAIL_REQUIRED: {
    message: "Email is required.",
    type: "Error",
    heading: "Input Required",
  },
  LOGIN_EMAIL_FORMAT_NOT_VALID: {
    message: "Email format is invalid.",
    type: "Error",
    heading: "Validation",
  },
  LOGIN_PASSWORD_REQUIRED: {
    message: "Password is required.",
    type: "Error",
    heading: "Input Required",
  },
  LOGIN_PASSWORD_LENGTH: {
    message: "Password must be at least 8 characters long.",
    type: "Error",
    heading: "Password Requirement",
  },
  LOGIN_PASSWORD_CRITERIA_NOT_MATCH: {
    message:
      "Password must contain an uppercase, a lowercase, a number and a special character.",
    type: "Error",
    heading: "Password Requirement",
  },
  LOGIN_FAILED_INCORRECT_EMAIL_PASSWORD: {
    message: "Incorrect username or password.",
    type: "Error",
    heading: "Validation",
  },
  LOGIN_ACCOUNT_LOCKOUT: {
    message:
      "Account temporarily locked due to multiple failed attempts. Retry in 60 minutes.",
    type: "Error",
    heading: "Account Locked",
  },
  LOGIN_USER_IS_INACTIVE: {
    message: "User not found.",
    type: "Error",
    heading: "Error",
  },
  LOGIN_ACTIVE_TOKEN_FOUND_LOGOUT_TO_CONTINUE: {
    message:
      "Simultaneous logins from multiple devices/locations are not allowed. To login, please log out from the active session. Note: Logging out will terminate the active session & any unsaved data will be lost.",
    type: "Warning",
    heading: "Multiple Logins Detected",
  },

  // Logout Messages
  USER_INACTIVITY_TIMEOUT: {
    message:
      "For security reasons, your session has timed out due to inactivity. Please log in again to access the application.",
    type: "Error",
    heading: "Session Timed Out",
  },

  //forget password messages
  FORGET_PASSWORD_SUCCESS: {
    message: "A reset link has been sent to your registered email ID.",
    type: "Success",
    heading: "Reset Link Sent",
  },
  FORGET_PASSWORD_USER_NOT_FOUND: {
    message: "Entered email address is invalid.",
    type: "Error",
    heading: "Validation",
  },
  FORGET_PASSWORD_INACTIVE_USERS: {
    message: "User not found.",
    type: "Error",
    heading: "Error",
  },
  RESET_LIMIT_EXCEEDED: {
    message:
      "Password reset attempt limit exceeded. Please try again after 24 hours.",
    type: "Error",
    heading: "Password Reset Limit Exceeded",
  },
  FORGOT_PASSWORD_LIMIT_EXCEEDED: {
    message:
      "Password reset attempt limit exceeded. Please try again after 24 hours.",
    type: "Error",
    heading: "Password Reset Limit Exceeded",
  },
  FORGOT_PASSWORD_INVALID_EMAIL_FORMAT: {
    message: "Email format is invalid.",
    type: "Error",
    heading: "Input Required",
  },

  // reset password messages
  RESET_PASSWORD_TOKEN_EXPIRED: {
    message: "The password reset link has expired. Please try again.",
    type: "Error",
    heading: "Reset Password Link Expired",
  },
  CHANGE_PASSWORD_SUCCESS: {
    message: "Password has been reset. Please login with your new password.",
    type: "Success",
    heading: "Password Reset Successful",
  },
  RESET_PASSWORD_LINK_INVALID: {
    message:
      "The reset link is not valid. Please ensure you've clicked on the correct link.",
    type: "Error",
    heading: "Invalid Password Reset Link",
  },

  // add company super admin
  COMPANY_NAME_ALREADY_EXISTS: {
    message: "Company name already exists.",
    type: "Error",
    heading: "Company Exist",
  },
  VAT_ALREADY_EXISTS: {
    message: "VAT already exists.",
    type: "Error",
    heading: "VAT Exist",
  },
  COMPANY_ONBOARDED_SUCCESSFULLY: {
    message: "Company onboarded successfully.",
    type: "Success",
    heading: "Company Onboarded",
  },

  // add material product admin
  NEW_MATERIAL_ADDED_SUCCESSFULLY: {
    message: "New material added successfully.",
    type: "Success",
    heading: "Material Added",
  },
  MATERIAL_NAME_ALREADY_EXISTS: {
    message: "Material name already exists.",
    type: "Error",
    heading: "Validation",
  },
  MATERIAL_UPDATED_SUCCESSFULLY: {
    message: "Material updated successfully.",
    type: "Success",
    heading: "Success",
  },
  MATERIAL_NAME_NOT_FOUND: {
    message: "Material name not found.",
    type: "Error",
    heading: "Validation",
  },
  MATERIAL_NOT_FOUND: {
    message: "Material not found.",
    type: "Error",
    heading: "Validation",
  },
  DIMENSION_SHOULD_BE_MORE_THEN_OR_EQUAL_TO_3: {
    message: "Please select at least 3 dimensions.",
    type: "Error",
    heading: "Validation",
  },
  DIMENSION_SHOULD_BE_LESS_THEN_OR_EQUAL_TO_5: {
    message: "Maximum 5 dimensions can be selected.",
    type: "Error",
    heading: "Validation",
  },
  MEASUREMENT_SHOULD_BE_MORE_THEN_OR_EQUAL_TO_1: {
    message: "Please select at least 1 measurement.",
    type: "Error",
    heading: "Validation",
  },
  MEASUREMENT_SHOULD_BE_LESS_THEN_OR_EQUAL_TO_2: {
    message: "Maximum 3 measurements can be selected.",
    type: "Error",
    heading: "Validation",
  },
  MATERIAL_NOT_FOUND_OR_ALREADY_DELETED: {
    message: "Material not found or has already been deleted.",
    type: "Error",
    heading: "Validation",
  },
  FILE_TEMPLATE_HAVE_MULTIPLE_SHEET: {
    message:
      "The template must contain a single sheet with the correct format and valid values.",
    type: "Error",
    heading: "Validation",
  },
  THIS_FIELD_IS_MANDATORY: {
    message: "This field is mandatory.",
    type: "Error",
    heading: "Validation",
  },
  MATERIAL_DELETED_SUCCESSFULLY: {
    message: "Selected material deleted successfully.",
    type: "Success",
    heading: "Material Deleted",
  },

  //add material product admin bulk upload
  FILE_TEMPLATE_IS_MISSING: {
    message:
      "The template you are trying to upload is invalid. Please download the valid template.",
    type: "Error",
    heading: "",
  },
  FILE_HEADERS_ARE_MISSING: {
    message:
      "The template you are trying to upload is empty or without headers.",
    type: "Error",
    heading: "Validation",
  },
  MISSING_MANADATORY_FIELDS: {
    message: "Please upload all mandatory fields.",
    type: "Error",
    heading: "",
  },

  // add Process admin
  NEW_PROCESS_ADDED_SUCCESSFULLY: {
    message: "New Process added successfully.",
    type: "Success",
    heading: "Process Added",
  },
  PROCESS_NAME_ALREADY_EXISTS: {
    message: "Process name already exists.",
    type: "Error",
    heading: "Validation",
  },
  PROCESS_UPDATED_SUCCESSFULLY: {
    message: "Process updated successfully.",
    type: "Success",
    heading: "Success",
  },
  PROCESS_NAME_NOT_FOUND: {
    message: "Process name not found.",
    type: "Error",
    heading: "Validation",
  },
  PROCESS_NOT_FOUND: {
    message: "Process not found.",
    type: "Error",
    heading: "Validation",
  },
  PARAMETER_SHOULD_BE_LESS_THEN_OR_EQUAL_TO_10: {
    message: "Maximum 10 parameters can be selected.",
    type: "Error",
    heading: "Validation",
  },
  PROCESS_NOT_FOUND_OR_ALREADY_DELETED: {
    message: "Process not found or has already been deleted.",
    type: "Error",
    heading: "Validation",
  },
  PROCESS_DELETED_SUCCESSFULLY: {
    message: "Selected process deleted successfully.",
    type: "Success",
    heading: "Process Deleted",
  },

  //Add machine
  NEW_MACHINE_ADDED_SUCCESSFULLY: {
    message: "New machine added successfully.",
    type: "Success",
    heading: "Machine Added",
  },
  MACHINE_MODEL_ALREADY_EXISTS_FOR_THIS_COMPANY: {
    message: "Machine name already exists.",
    type: "Error",
    heading: "Validation",
  },
  MACHINE_UPDATED_SUCCESSFULLY: {
    message: "Machine updated successfully.",
    type: "Success",
    heading: "Success",
  },
  MACHINE_NAME_NOT_FOUND: {
    message: "Machine name not found.",
    type: "Error",
    heading: "Validation",
  },
  NEW_MACHINE_ADDED_FAILED: {
    message: "Machine name not found.",
    type: "Error",
    heading: "Validation",
  },
  MACHINE_NOT_FOUND: {
    message: "Machine not found.",
    type: "Error",
    heading: "Validation",
  },
  PROCESS_SHOULD_NOT_BE_MORE_THEN_OR_5: {
    message: "Maximum 5 dimensions can be selected.",
    type: "Error",
    heading: "Validation",
  },
  MACHINE_DELETED_SUCCESSFULLY: {
    message: "Selected machine deleted successfully.",
    type: "Success",
    heading: "Machine Deleted",
  },

  //product create
  STATUS_UPDATED_SUCCESSFULLY: {
    message: "Product reference created successfully.",
    type: "Success",
    heading: "Product Reference Added",
  },
  PRODUCT_REFERENCE_CREATED_SUCCESSFULLY: {
    message: "Product reference created successfully.",
    type: "Success",
    heading: "Product Reference Added",
  },
  PRODUCT_REFERENCE_UPDATED_SUCCESSFULLY: {
    message: "Product reference updated successfully.",
    type: "Success",
    heading: "Product Reference Updated",
  },
  PRODUCT_REFERENCE_DELETED_SUCCESSFULLY: {
    message: "Product reference deleted successfully.",
    type: "Success",
    heading: "Product Reference Deleted",
  },
  REFERENCE_PART_ALREADY_EXISTS: {
    message: "Reference part already exists.",
    type: "Error",
    heading: "Validation",
  },
  REFERENCE_PART_NOT_FOUND: {
    message: "Reference part not found.",
    type: "Error",
    heading: "Validation",
  },
  PRODUCT_REFERENCE_MATERIAL_CREATED_SUCCESSFULLY: {
    message: "Product reference material created successfully.",
    type: "Success",
    heading: "Material Added",
  },
  PRODUCT_REFERENCE_MATERIAL_UPDATED_SUCCESSFULLY: {
    message: "Product reference material updated successfully.",
    type: "Success",
    heading: "Material Updated",
  },
  PRODUCT_REFERENCE_PROCESS_UPDATED_SUCCESSFULLY: {
    message: "Product reference process updated successfully.",
    type: "Success",
    heading: "process updated",
  },
  REFERENCE_PART_IS_MISSING: {
    message: "Product reference part is missing.",
    type: "Success",
    heading: "validation",
  },
  PRODUCT_REFERENCE_MATERIAL_CREATED_FAILED: {
    message: "Failed to create product reference material.",
    type: "Error",
    heading: "Error",
  },
  PRODUCT_REFERENCE_MATERIAL_ALREADY_EXISTS: {
    message: "product reference material already exists.",
    type: "Error",
    heading: "Error",
  },
  MATERIAL_CONFIG_MAPPING_NOT_FOUND: {
    message: "Material configuration mapping not found.",
    type: "Error",
    heading: "Validation",
  },
  PRODUCT_REFERENCE_PROCESS_CREATED_SUCCESSFULLY: {
    message: "Product reference process created successfully.",
    type: "Success",
    heading: "Process Added",
  },
  MATERIAL_IS_ASSOCIATED_WITH_PROCESS: {
    message: "Material can not be deleted due to associated with process.",
    type: "Error",
    heading: "Validation",
  },
  PRODUCT_REFERENCE_PROCESS_CREATED_FAILED: {
    message: "Failed to create product reference process.",
    type: "Error",
    heading: "Error",
  },
  PRODUCT_REFERENCE_PROCESS_ALREADY_EXISTS: {
    message: "Product reference process already exists.",
    type: "Error",
    heading: "Error",
  },
  PROCESS_STEP_DELETED_SUCCESSFULLY: {
    message: "Selected process deleted successfully.",
    type: "Success",
    heading: "Process Deleted",
  },
  MATERIAL_STEP_DELETED_SUCCESSFULLY: {
    message: "Selected material deleted successfully.",
    type: "Success",
    heading: "Material Deleted",
  },
  PCF_CREATED_SUCCESSFULLY: {
    message: "Product reference part added successfully.",
    type: "Success",
    heading: "Process reference added",
  },
  BATCH_ALREADY_EXISTS: {
    message: "Product reference part batch already exists.",
    type: "Error",
    heading: "Error",
  },
  PCF_CREATED_FAILED: {
    message: "Product reference part creation failed.",
    type: "Error",
    heading: "Error",
  },
  PRODUCT_MASTER_DATA_NOT_FOUND: {
    message: "Product master data not found.",
    type: "Error",
    heading: "Error",
  },
  PCF_CALCULATE_SUCCESSFULLY: {
    message: "PCF calculation has been saved successfully.",
    type: "Success",
    heading: "Success",
  },
  PCF_REPORT_SAVED_SUCCESSFULLY: {
    message: "PCF report has been saved successfully",
    type: "Success",
    heading: "Success",
  },
  PCF_REPORT_GENERATED_SUCCESSFULLY: {
    message: "PCF report has been updated successfully",
    type: "Success",
    heading: "Success",
  },
  IMAGE_SUCCESSFULLY_UPLOADED: {
    message: "Image successfully uploaded.",
    type: "Success",
    heading: "Success",
  },
  PCF_UPDATED_SUCCESSFULLY: {
    message: "Product reference part updated successfully.",
    type: "Success",
    heading: "Process reference updated",
  },
  ORDER_DELETED_SUCCESSFULLY: {
    message: "PCF enquiry has been deleted successfully",
    type: "Success",
    heading: "PCF enquiry deleted",
  },
  UNABLE_TO_REMOVE_MATERIAL_AS_IT_IS_LINKED_TO_PRODUCT_REFERENCE_PART: {
    message:
      "Unable to remove the material as it is linked to a product reference part.",
    type: "Error",
    heading: "Error",
  },

  SCOPE_ESTIMATE_ADDED_SUCCESSFULLY: {
    message: "CCF estimate adjustment has been updated successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },

  SCOPE_ESTIMATE_UPDATED_SUCCESSFULLY: {
    message: "CCF estimate adjustment has been updated successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },

  SCOPE_ESTIMATE_ADDED_SUCCESSFULLY_1: {
    message: "CCF estimated values for scope 1 have been updated successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },
  SCOPE_ESTIMATE_ADDED_SUCCESSFULLY_2: {
    message: "CCF estimated values for scope 2 have been updated successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },
  SCOPE_ESTIMATE_ADDED_SUCCESSFULLY_3: {
    message: "CCF estimated values for scope 3 have been updated successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },
  AGREE_CONFIRM: {
    message: "CCF estimated values have been saved successfully.",
    type: "Success",
    heading: "Scope added successfully.",
  },
  // FILE_IS_MISSING: {
  //   message: "Image file i.",
  //   type: "Success",
  //   heading: "Process reference added",
  // }
};

export const getErrorMessage = (key: string): ErrorMessage =>
  errorMessages[key] || {
    message: "An error occurred. Please try again later.",
    type: "Error",
    heading: "Error",
  };
